import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';

import leftNet from '../../../images/Net_Left_side.svg';
import rightNet from '../../../images/Net_Right_side.svg';

import './dataProtection.scss';

const DataProtection = (props) => (
  <div className="data-protection-container">
    <img className="data-image-left" src={leftNet} alt="" />
    <img className="data-image-right" src={rightNet} alt="" />
    <div className="main-data-container container-wrapper">
      <div className="title-wrapper lg:w-1/2 mx-auto">
        <h1 className="impressum-title  section-title custom-font-inter">
          {props.data.title}
        </h1>
      </div>
      <div className="data-content-wrapper-one">
        <ReactMarkdown className="impressum-content">
          {props.data.content.data.content}
        </ReactMarkdown>
      </div>

    </div>
  </div>
);

DataProtection.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    informationContent: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    informationTitle: PropTypes.string.isRequired,
    dataProtectionContent: PropTypes.string.isRequired,
    dataProtectionTitle: PropTypes.string.isRequired,
    analysisToolsContent: PropTypes.string.isRequired,
    DataRecordingContent: PropTypes.string.isRequired,
    AnalysisToolsTitle: PropTypes.string.isRequired,
    dataRecordingTitle: PropTypes.string.isRequired,
    generalInformationContent: PropTypes.string.isRequired,
    generalInformationTitle: PropTypes.string.isRequired,
    firstPartTitle: PropTypes.string.isRequired,
    secondPartTitle: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default DataProtection;
