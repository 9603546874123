import React, { useEffect } from 'react';
import { BsCircle } from 'react-icons/bs';
import PropTypes from 'prop-types';
import AOS from 'aos';

import './companyHistory.scss';

const CompanyHistory = (props) => {
  const { pageContext } = props;
  const lngs = ['en', 'bs', 'tr'];
  const splitString = lngs.includes(pageContext.locale)
    ? `/${pageContext.locale}/about-us`
    : '/about-us';
  const isBrowser = typeof window !== 'undefined';
  const home = isBrowser && window?.location.pathname.split(splitString)[0];
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="mt-4 md:mt-8">
      <div className="title-wrapper container-wrapper">
        <h2 className="history-title custom-font-inter">
          {home && props.data.title}
        </h2>
      </div>
      <div className="history-container container-wrapper">
        <div className="image-wrapper relative">
          <img src={props.data.image.url} alt="" />
        </div>
        <div className="right-container ">
          <div className="text-container">
            <ul className="text-list">
              <div data-aos="fade-up" data-aos-duration="1000">
                {props.data.companyImportantDates.map((item) => (
                  <li className="text-wrapper" key={item.id}>
                    <span>
                      <BsCircle className="circle" />
                    </span>
                    <span className="circle-line" />
                    <p className="title">
                      {`${item.date} - `}
                      <span>{item.title}</span>
                    </p>
                  </li>
                ))}
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
CompanyHistory.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    companyImportantDates: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default CompanyHistory;
