import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { MdArrowForwardIos, MdArrowBackIos } from 'react-icons/md';
import { IoMdOptions } from 'react-icons/io';

import Items from './Items';
import MobileFilter from './mobileFilter';

import useNews from '../../../queries/newsQueries';
import useBlogs from '../../../queries/blogsQueries';

import './news&Blog.scss';
import './radioButtons.scss';

const typeFilterMapping = {
  type: {
    news: 'News',
    blog: 'Blog',
  },
  category: {
    sites: 'Construction Sites',
    issues: 'Project Issues',
    business: 'Business',
    market: 'Market Values',
    career: 'Career Paths',
  },
};

const NewsAndBlog = (props) => {
  const { pageContext } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { readMore } = props.data;
  const newsData = useNews();
  const blogsData = useBlogs();
  const langNewsData = newsData.filter(
    (item) => item.locale === props.pageContext.locale,
  );
  const langBlogsData = blogsData.filter(
    (item) => item.locale === props.pageContext.locale,
  );
  const [allNewsAndBlogs] = useState([...langNewsData, ...langBlogsData]);

  const [currentItems, setCurrentItems] = useState(allNewsAndBlogs);
  const [currentItemsForPage, setCurrentItemsForPage] = useState(allNewsAndBlogs);
  const [selectFilter, setSelectFilter] = useState({
    type: 'all',
    category: [],
  });
  const [itemOffset, setItemOffset] = useState(0);

  const pageCount = Math.ceil(currentItems.length / 4);
  const selectedPage = Math.ceil(itemOffset / 4);

  useEffect(() => {
    let filteredContent = allNewsAndBlogs.filter((item) => {
      if (typeFilterMapping.type[selectFilter.type]) {
        return item.newsOrBlog === typeFilterMapping.type[selectFilter.type];
      }
      return item;
    });

    filteredContent = filteredContent.filter((item) => {
      if (selectFilter.category.length > 0) {
        return selectFilter.category.includes(item.categories);
      }
      return item;
    });
    setCurrentItems(filteredContent);
    setItemOffset(0);
  }, [selectFilter, allNewsAndBlogs]);

  const onTypeChange = (selectedType) => {
    if (selectFilter.type === selectedType) {
      return;
    }
    setSelectFilter({ ...selectFilter, type: selectedType });
  };

  const onCategoryChange = (selectedCategory) => {
    const stateCategories = selectFilter.category;

    if (stateCategories[stateCategories.indexOf(selectedCategory)]) {
      stateCategories.splice(stateCategories.indexOf(selectedCategory), 1);
      return setSelectFilter({
        ...selectFilter,
        category: stateCategories,
      });
    }
    stateCategories.push(selectedCategory);

    return setSelectFilter({
      ...selectFilter,
      category: stateCategories,
    });
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 4) % currentItems.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const endOffset = itemOffset + 4;
    setCurrentItemsForPage(currentItems.slice(itemOffset, endOffset));
  }, [itemOffset, currentItems]);
  return (
    <div className="main-news-blog-wrapper">
      <div className="nb-title-wrapper-mobile">
        <h2>{props.data.title}</h2>
      </div>
      {/* Mobile filter */}
      <div className="container-wrapper">
        <button className="filter-btn" type="button" onClick={() => setIsOpen(true)}>
          <IoMdOptions />
          <span className="button-title custom-font-inter">
            {props.data.filterContent}
          </span>
        </button>
      </div>
      {isOpen && (
        <MobileFilter
          propsData={props}
          setIsOpen={setIsOpen}
          selectFilter={selectFilter}
          allNewsAndBlogs={allNewsAndBlogs}
          langBlogsData={langBlogsData}
          onCategoryChange={onCategoryChange}
          onTypeChange={onTypeChange}
        />
      )}
      <div className="news-container container-wrapper">
        <div className="news-left">
          <div className="radio-buttons-container">
            <div className="buttons-wrapper">
              <h3 className="left-title">{props.data.filterSection}</h3>
              <label>
                <input
                  type="radio"
                  className="radio-input radio"
                  name="filterSection"
                  id="langNewsData"
                  checked={selectFilter.type === 'news'}
                  onChange={() => {
                    onTypeChange('news');
                  }}
                />
                <h3 className="left-subtitle">{props.data.news}</h3>
              </label>
              <label>
                <input
                  type="radio"
                  className="radio-input radio"
                  name="filterSection"
                  id="langBlogsData"
                  checked={selectFilter.type === 'blog'}
                  onChange={() => {
                    onTypeChange('blog');
                  }}
                />
                <h3 className="left-subtitle">{props.data.blog}</h3>
              </label>
              <label>
                <input
                  type="radio"
                  className="radio-input radio"
                  name="filterSection"
                  id="allNewsAndBlogs"
                  checked={selectFilter.type === 'all'}
                  onChange={() => {
                    onTypeChange('all');
                  }}
                />
                <h3 className="left-subtitle">{props.data.all}</h3>
              </label>
            </div>
            <div className="buttons-wrapper">
              <h3 className="left-title">{props.data.categories}</h3>
              <label>
                <input
                  type="checkbox"
                  className="checkbox-input checkbox"
                  id={allNewsAndBlogs.categories}
                  name="checkbox"
                  value="Construction Sites"
                  checked={selectFilter.category.sites}
                  onChange={() => {
                    onCategoryChange('Construction Sites');
                  }}
                />
                <h3 className="left-subtitle">
                  {props.data.constructionSites}
                </h3>
              </label>
              <label>
                <input
                  type="checkbox"
                  className="checkbox-input checkbox"
                  id={langBlogsData.categories}
                  name="checkbox"
                  value="Project Issues"
                  checked={selectFilter.category.issues}
                  onChange={() => {
                    onCategoryChange('Project Issues');
                  }}
                />
                <h3 className="left-subtitle">{props.data.projectIssues}</h3>
              </label>
              <label>
                <input
                  type="checkbox"
                  className="checkbox-input checkbox"
                  id={langBlogsData.categories}
                  name="checkbox"
                  value="Business"
                  checked={selectFilter.category.business}
                  onChange={() => {
                    onCategoryChange('Business');
                  }}
                />
                <h3 className="left-subtitle">{props.data.business}</h3>
              </label>
              <label>
                <input
                  type="checkbox"
                  className="checkbox-input checkbox"
                  id={langBlogsData.categories}
                  name="checkbox"
                  value="Market Values"
                  checked={selectFilter.category.market}
                  onChange={() => {
                    onCategoryChange('Market Values');
                  }}
                />
                <h3 className="left-subtitle">{props.data.marketValues}</h3>
              </label>
              <label>
                <input
                  type="checkbox"
                  className="checkbox-input checkbox"
                  id={langBlogsData.categories}
                  name="checkbox"
                  value="Career Paths"
                  checked={selectFilter.category.career}
                  onChange={() => {
                    onCategoryChange('Career Paths');
                  }}
                />
                <h3 className="left-subtitle">{props.data.careerPaths}</h3>
              </label>
            </div>
          </div>
        </div>
        <div className="news-right">
          <h2 className="nb-title-desktop">{props.data.title}</h2>
          <Items
            currentItems={currentItemsForPage}
            readMore={readMore}
            pageContext={pageContext}
          />
        </div>
      </div>
      <div className="pagination-wrapper">
        <ul className="container-wrapper">
          <li>
            <ReactPaginate
              className="pagination"
              breakLabel="..."
              disabledClassName="page-link"
              activeClassName="active"
              nextLabel={(
                <span className=" flex lg:text-lg  2xl:text-xl">
                  {props.data.next}
                  <MdArrowForwardIos className="my-auto align-middle text-xs md:ml-2 md:text-base" />
                </span>
                  )}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={(
                <span className=" flex lg:text-lg 2xl:text-xl">
                  <MdArrowBackIos className="my-auto text-xs md:mr-2 md:text-base" />
                  {props.data.previous}
                </span>
                  )}
              renderOnZeroPageCount={null}
              forcePage={selectedPage}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};
NewsAndBlog.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    blog: PropTypes.string.isRequired,
    filterContent: PropTypes.string.isRequired,
    filterSection: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    news: PropTypes.string.isRequired,
    all: PropTypes.string.isRequired,
    sortBy: PropTypes.string.isRequired,
    newest: PropTypes.string.isRequired,
    popular: PropTypes.string.isRequired,
    categories: PropTypes.string.isRequired,
    constructionSites: PropTypes.string.isRequired,
    projectIssues: PropTypes.string.isRequired,
    projectBusiness: PropTypes.string.isRequired,
    business: PropTypes.string.isRequired,
    marketValues: PropTypes.string.isRequired,
    careerPaths: PropTypes.string.isRequired,
    backToNewsAndBlog: PropTypes.string.isRequired,
    next: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    previous: PropTypes.string.isRequired,
    readMore: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default NewsAndBlog;
