/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { IoMdClose } from 'react-icons/io';
import AOS from 'aos';

import 'aos/dist/aos.css';

import './radioButtons.scss';
import './news&Blog.scss';
import './mobileFilter.scss';

const MobileFilter = (props) => {
  const {
    propsData,
    selectFilter,
    onTypeChange,
    onCategoryChange,
    setIsOpen,
    langBlogsData,
    allNewsAndBlogs,
  } = props;
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <div
        className="overlay-wrapper"
        data-aos="fade-zoom-in"
        data-aos-easing="ease-in-back"
        data-aos-delay="0"
        data-aos-offset="0"
        onClick={() => setIsOpen(false)}
      />
      <div className="main-mobile-filter-wrapper">
        <div className="filter-close-container">
          <IoMdClose
            className="filter-close"
            size={30}
            onClick={() => setIsOpen(false)}
          />
        </div>
        <div
          className="mobile-filter-button-container"
          data-aos="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500"
        >
          <div className="radio-buttons-container">
            <div className="buttons-wrapper">
              <h3 className="left-mobile-title custom-font-inter">
                {propsData.data.filterSection}
              </h3>
              <label>
                <input
                  type="radio"
                  className="radio-input radio"
                  name="filterSection"
                  id="langNewsData"
                  checked={selectFilter.type === 'news'}
                  onChange={() => {
                    onTypeChange('news');
                  }}
                />
                <h3 className="left-subtitle">{propsData.data.news}</h3>
              </label>
              <label>
                <input
                  type="radio"
                  className="radio-input radio"
                  name="filterSection"
                  id="langBlogsData"
                  checked={selectFilter.type === 'blog'}
                  onChange={() => {
                    onTypeChange('blog');
                  }}
                />
                <h3 className="left-subtitle">{propsData.data.blog}</h3>
              </label>
              <label>
                <input
                  type="radio"
                  className="radio-input radio"
                  name="filterSection"
                  id="allNewsAndBlogs"
                  checked={selectFilter.type === 'all'}
                  onChange={() => {
                    onTypeChange('all');
                  }}
                />
                <h3 className="left-subtitle">{propsData.data.all}</h3>
              </label>
            </div>
            <div className="buttons-wrapper">
              <h3 className="left-title">{propsData.data.categories}</h3>
              <label>
                <input
                  type="checkbox"
                  className="checkbox-input checkbox"
                  id={allNewsAndBlogs.categories}
                  name="checkbox"
                  value="Construction Sites"
                  checked={selectFilter.category.sites}
                  onChange={() => {
                    onCategoryChange('Construction Sites');
                  }}
                />
                <h3 className="left-subtitle">
                  {propsData.data.constructionSites}
                </h3>
              </label>
              <label>
                <input
                  type="checkbox"
                  className="checkbox-input checkbox"
                  id={langBlogsData.categories}
                  name="checkbox"
                  value="Project Issues"
                  checked={selectFilter.category.issues}
                  onChange={() => {
                    onCategoryChange('Project Issues');
                  }}
                />
                <h3 className="left-subtitle">
                  {propsData.data.projectIssues}
                </h3>
              </label>
              <label>
                <input
                  type="checkbox"
                  className="checkbox-input checkbox"
                  id={langBlogsData.categories}
                  name="checkbox"
                  value="Business"
                  checked={selectFilter.category.business}
                  onChange={() => {
                    onCategoryChange('Business');
                  }}
                />
                <h3 className="left-subtitle">{propsData.data.business}</h3>
              </label>
              <label>
                <input
                  type="checkbox"
                  className="checkbox-input checkbox"
                  id={langBlogsData.categories}
                  name="checkbox"
                  value="Market Values"
                  checked={selectFilter.category.market}
                  onChange={() => {
                    onCategoryChange('Market Values');
                  }}
                />
                <h3 className="left-subtitle">{propsData.data.marketValues}</h3>
              </label>
              <label>
                <input
                  type="checkbox"
                  className="checkbox-input checkbox"
                  id={langBlogsData.categories}
                  name="checkbox"
                  value="Career Paths"
                  checked={selectFilter.category.career}
                  onChange={() => {
                    onCategoryChange('Career Paths');
                  }}
                />
                <h3 className="left-subtitle">{propsData.data.careerPaths}</h3>
              </label>
            </div>
          </div>
          <div className="button-container custom-font-inter">
            <button
              className="btn custom-font-inter"
              type="submit"
              onClick={() => setIsOpen(false)}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
MobileFilter.propTypes = {
  propsData: PropTypes.string.isRequired,
  selectFilter: PropTypes.string.isRequired,
  onTypeChange: PropTypes.string.isRequired,
  onCategoryChange: PropTypes.string.isRequired,
  setIsOpen: PropTypes.string.isRequired,
  langBlogsData: PropTypes.string.isRequired,
  allNewsAndBlogs: PropTypes.string.isRequired,
  blog: PropTypes.string.isRequired,
  filterSection: PropTypes.string.isRequired,
  news: PropTypes.string.isRequired,
  all: PropTypes.string.isRequired,
  categories: PropTypes.string.isRequired,
  constructionSites: PropTypes.string.isRequired,
  projectIssues: PropTypes.string.isRequired,
  business: PropTypes.string.isRequired,
  marketValues: PropTypes.string.isRequired,
  careerPaths: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default MobileFilter;
