/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CustomLink from '../../CustomLink';
import { localizePath } from '../../../utils/localize';

import './landingPage.scss';

const LandingPage = (props) => {
  const { pageContext } = props;
  const [backgroundLeft, setBackgroundLeft] = useState(props.data.logoLeft.url);
  const [backgroundRight, setBackgroundRight] = useState(
    props.data.logoRight.url,
  );
  return (
    <div>
      <div className="main-div">
        <div className="container-left">
          <CustomLink
            link={{
              url: `${localizePath({
                ...pageContext,
                slug: props.data.slug,
              })}`,
            }}
            to={props.data.slug}
          >
            <div
              className="logo"
              onMouseEnter={() => setBackgroundLeft(props.data.imageLeft.url)}
              onMouseOut={() => setBackgroundLeft(props.data.logoLeft.url)}
            >
              <img src={backgroundLeft} alt="" />
            </div>
          </CustomLink>
        </div>
        <div className="container-right">
          <a
            href="https://www.3n-ing.de/home"
            className="logo"
            onMouseEnter={() => setBackgroundRight(props.data.imageRight.url)}
            onMouseOut={() => setBackgroundRight(props.data.logoRight.url)}
          >
            <img src={backgroundRight} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};
LandingPage.propTypes = {
  data: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    imageLeft: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    imageRight: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    logoLeft: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    logoRight: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default LandingPage;
