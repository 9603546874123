/* eslint-disable no-dupe-keys */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable operator-linebreak */
import React from 'react';
import PropTypes from 'prop-types';

import LandingPage from './Sections/LandingPage';
import HeroSection from './Sections/Hero';
import OurOffer from './Sections/OurOffer';
import OurProjects from './Sections/OurProjects';
import CompanyHistory from './Sections/CompanyHistory';
// import NewsSection from './Sections/NewsSection';
import OurTeam from './Sections/OurTeam';
import AboutUs from './Sections/AboutUs';
import CompanyValues from './Sections/CompanyValues';
import ProjectSlider from './Sections/ProjectSlider';
import AllProjects from './Sections/AllProjects';
import Impressum from './Sections/Impressum';
import DataProtection from './Sections/DataProtection';
import ProjectDetailPage from './ProjectsDetailPage';
import Contact from './Sections/Contact';
import NewsAndBlog from './Sections/News&Blog';
import NewsDetailPage from './NewsDetailPage';

const sectionComponents = {
  'section.landing': LandingPage,
  'section.hero': HeroSection,
  'section.our-offer': OurOffer,
  'section.our-projects': OurProjects,
  'section.company-history': CompanyHistory,
  // 'section.news-section': NewsSection,
  'section.meet-the-team': OurTeam,
  'section.about-us': AboutUs,
  'section.our-values': CompanyValues,
  'section.project-slider': ProjectSlider,
  'section.all-projects': AllProjects,
  'section.single-project': ProjectDetailPage,
  'section.impressum': Impressum,
  'section.data-protection': DataProtection,
  'section.contact-page': Contact,
  'section.news-and-blog': NewsAndBlog,
  'section.single-news': NewsDetailPage,
};

const Section = ({ sectionData, locale, pageContext }) => {
  const SectionComponent =
    sectionComponents[sectionData.strapi_component || sectionData.__component];
  if (!SectionComponent) {
    return null;
  }
  return (
    <SectionComponent
      data={sectionData}
      locale={locale}
      pageContext={pageContext}
    />
  );
};
const Sections = (props) => {
  const lang = props.locale;
  return (
    <div className="flex flex-col">
      {props.sections.map((section, i) => (
        <Section
          pageContext={props.pageContext}
          sectionData={section}
          locale={lang}
          key={`${section.strapi_component}${(section.id, i)}`}
        />
      ))}
    </div>
  );
};

Section.propTypes = {
  locale: PropTypes.string.isRequired,
  sectionData: PropTypes.shape({
    strapi_component: PropTypes.string.isRequired,
    __component: PropTypes.string,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

Sections.propTypes = {
  locale: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default Sections;
