/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Sections from '../components/sections';
import Seo from '../components/seo';

import '../styles/global.scss';

const DynamicPage = ({ data, pageContext }) => {
  const { contentSections, metaData, locale } = data.strapiPage;
  const global = data.strapiGlobal;
  return (
    <>
      <Seo seo={metaData} global={global} />
      <Layout global={global} pageContext={pageContext}>
        <Sections
          pageContext={pageContext}
          sections={contentSections}
          locale={locale}
        />
      </Layout>
    </>
  );
};

export default DynamicPage;

export const query = graphql`
  query DynamicPageQuery($id: String!, $locale: String!) {
    strapiGlobal(locale: { eq: $locale }) {
      navbar {
        companyLogo {
          url
        }
        companyLogoBlack {
          url
        }
        mainLinks {
          title
          url
          id
        }
      }
      siteName
      siteDescription
      defaultSeo {
        metaTitle
        metaDescription
        shareImage {
          url
        }
      }
      footer {
        footerContentTitle {
          title
        }
        company {
          title
          url
          id
        }
        service {
          title
          url
          id
        }
        contact {
          addressTitle
          address
          phoneTitle
          phoneNumber
          emailTitle
          email
        }
        legal {
          title
          url
          id
        }
        copyright {
          titleOne
          titleTwo
        }
        footerBackgroundImage {
          url
        }
        footerBackgroundMobileImage {
          url
        }
        companyLogo {
          url
        }
      }
      projectSliderTitle
    }
    strapiPage(id: { eq: $id }) {
      slug
      shortName
      metaData {
        metaTitle
        metaDescription
        shareImage {
          url
        }
      }
      contentSections {
        ... on STRAPI__COMPONENT_SECTION_LANDING {
          id
          strapi_component
          slug
          imageLeft {
            url
          }
          imageRight {
            url
          }
          logoLeft {
            url
          }
          logoRight {
            url
          }
        }
        ... on STRAPI__COMPONENT_SECTION_HERO {
          id
          strapi_component
          image {
            url
          }
          imageMobile {
            url
          }
          description
          title
        }
        ... on STRAPI__COMPONENT_SECTION_OUR_OFFER {
          id
          strapi_component
          title
          ourOfferDescription {
            data {
              ourOfferDescription
            }
          }
          ourOfferContainer {
            id
            imageUrl
            description
            content {
              data {
                content
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_SECTION_OUR_PROJECTS {
          id
          strapi_component
          title
          locationTitle
          buildingTypeTitle
          projectDetailsTitle
          ourRoleTitle
          numberOfObjectsTitle
          surfaceAreaTitle
        }
        ... on STRAPI__COMPONENT_SECTION_COMPANY_HISTORY {
          id
          strapi_component
          title
          image {
            url
          }
          companyImportantDates {
            date
            title
            content
            id
          }
        }
        ... on STRAPI__COMPONENT_SECTION_NEWS_SECTION {
          id
          strapi_component
          title
          newsContainer {
            constructionTitle
          }
        }
        ... on STRAPI__COMPONENT_SECTION_MEET_THE_TEAM {
          id
          strapi_component
          title
          roleTitle
          joinedTitle
          aboutMemberTitle
          ourTeamDescription {
            data {
              ourTeamDescription
            }
          }
        }
        ... on STRAPI__COMPONENT_SECTION_ABOUT_US {
          id
          strapi_component
          title
          content {
            data {
              content
            }
          }
        }
        ... on STRAPI__COMPONENT_SECTION_OUR_VALUES {
          id
          strapi_component
          title
          ourValues {
            id
            imageUrl
            description
            content {
              data {
                content
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_SECTION_PROJECT_SLIDER {
          id
          strapi_component
          title
        }
        ... on STRAPI__COMPONENT_SECTION_ALL_PROJECTS {
          id
          strapi_component
          title
          description
        }
        ... on STRAPI__COMPONENT_SECTION_IMPRESSUM {
          id
          strapi_component
          title
          founder
          founderTitle
          addressTitle
          address
          phone
          phoneTitle
          web
          webTitle
          email
          emailTitle
          managerPhone
          managerPhoneTitle
          memberContent
          memberNrContent
          memberNrTitle
          memberTitle
          privacyPolicyTitle
          ustNr
          ustTitle
          content {
            data {
              content
            }
          }
        }
        ... on STRAPI__COMPONENT_SECTION_DATA_PROTECTION {
          id
          strapi_component
          title
          content {
            data {
              content
            }
          }
        }
        ... on STRAPI__COMPONENT_SECTION_CONTACT_PAGE {
          id
          strapi_component
          contactTitle
          addressDE
          phone1
          contactFormTitle
          placeholderMail
          placeholderMessage
          buttonSent
          contactInformationTitle
          mail
          phone2
          placeholderName
          placeholderPhoneNumber
          buttonSend
          validEmail
          requiredEmail
          messageTextSent
          messageSent
        }
        ... on STRAPI__COMPONENT_SECTION_NEWS_AND_BLOG {
          id
          strapi_component
          title
          blog
          news
          all
          sortBy
          newest
          categories
          projectIssues
          marketValues
          readMore
          next
          previous
          backToNewsAndBlog
          careerPaths
          business
          constructionSites
          popular
          filterSection
          filterContent
          backToNewsAndBlogSlug
          backToNewsAndBlog
        }
      }
      locale
    }
  }
`;
